import mediumZoom from 'medium-zoom'
import "intersection-observer";
import scrollama from "scrollama";
import $ from "jquery";
import Bowser from "bowser";
import { annotate } from 'rough-notation';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ShopifyClient from 'shopify-buy';
import getUserLocale from 'get-user-locale';
import { decode } from 'js-base64';
import 'slick-carousel';

require("ajaxchimp")

const bowser = Bowser.getParser(window.navigator.userAgent);

// Set if mobile on site
if (bowser.getPlatformType() == "mobile") $("body").addClass("mobile")

// Fade site in on load
window.onload = () => {
  if ($(window).height() < $("#hero").height()) {
    const navigationHeight = $(window).width() > 600 ? $("#hero .navigation").height() : $("#topnav").height() + 20
    const imageHeight = $(window).height() - navigationHeight - $("#hero .description_container").height();

    $("#hero .image_container img").css({ height: `${imageHeight}px` })
  }

  document.querySelector("body").style.opacity = 1;

  // setTimeout(() => {
  //   annotate(document.querySelector('#big_price'), { type: 'crossed-off', color: "#f03e3e", animationDuration: 640 }).show();
  //
  //   setTimeout(() => {
  //     annotate(document.querySelector('#small_price'), { type: 'circle', color: "#74b816", animationDuration: 640 }).show();
  //   }, 640)
  // }, 640)
};

// Animate to point on page
$('a[href="#hero"], a[href="#details"], a[href="#collection"], a[href="#story"], a[href="#faq"]').click((event) => {
    event.preventDefault();

    $("html, body").animate({ scrollTop: $($(event.target).closest("a").attr('href')).offset().top - 56 }, 320);
});
$('.view_collection').click((event) => {
    event.preventDefault();

    $("html, body").animate({ scrollTop: $("#collection").offset().top - 56 }, 320);
});

// Set active menu item
const stepScroller = scrollama();
stepScroller.setup({ step: ".step" }).onStepEnter(response => {
  $(`#topnav .menu_item`).removeClass("active")
  $(`#topnav .${$(response.element).attr("id")}`).addClass("active")
})
window.addEventListener("resize", stepScroller.resize);

// Zoom images
mediumZoom(document.querySelectorAll('.zoomable'), {
  margin: 20,
  background: "rgba(255, 249, 242, 0.95)"
})

// Toggle menu on mobile
window.toggleMenu = () => {
  var topnav = document.getElementById("topnav");
  if (topnav.className === "topnav") {
    topnav.className += " responsive";
  } else {
    topnav.className = "topnav"
  }
}

// Hero 360 scroll
const canvas = document.getElementById("hero_canvas");
const context = canvas.getContext("2d");

canvas.width = 1400;
canvas.height = 950;

const frameCount = 263;
const currentFrame = index => `/sequence/jewelry_box_${(index + 1).toString()}.jpg`;

const images = []
const jewelry_box = { frame: 0 };

for (let i = 0; i < frameCount; i++) {
  const img = new Image();
  img.src = currentFrame(i);
  images.push(img);
}

if (bowser.getPlatformType() != "mobile") {
  gsap.registerPlugin(ScrollTrigger);
  gsap.to(jewelry_box, {
    frame: frameCount - 1,
    trigger: "frame",
    scrollTrigger: {
      scrub: 0.5,
      pin: "#hero", // pin the trigger element while active
      start: "top top", // when the top of the trigger hits the top of the viewport
      end: "+=2000px", // end after scrolling 500px beyond the start
    },
    onUpdate: render // use animation onUpdate instead of scrollTrigger's onUpdate
  });

  images[0].onload = render;
}

function render() {
  const newIndex = parseInt(jewelry_box.frame);
  if (newIndex > 60) {
    $("#hero .description_container").addClass("invisible");
  } else {
    $("#hero .description_container").removeClass("invisible");
  }

  context.clearRect(0, 0, canvas.width, canvas.height);
  context.drawImage(images[newIndex], 0, 0);
}

// Load products
let language = !!getUserLocale() ? getUserLocale() : "en-US";
fetch('http://ip-api.com/json').then(res => res.json()).then(response => {
  language = `${language.split("-")[0]}-${response.countryCode}`
  fetchShopifyProducts();
}).catch((data, status) => {
  fetchShopifyProducts();
})

function fetchShopifyProducts() {
  const shopifyClient = ShopifyClient.buildClient({
    domain: 'holgersindbaek.myshopify.com',
    storefrontAccessToken: 'd3d8c0a39f8fd2b51ab6ecc64d4504a8',
    language
  });

  shopifyClient.collection.fetchWithProducts("Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzI2MTg1MDMzMzMzNQ==", { productsFirst: 50 }).then((collection) => {
    const cheapestProduct = collection.products.filter(product => product.availableForSale).sort((productA, productB) => parseInt(productA.variants[0].price) - parseInt(productB.variants[0].price))[0]
    const collectionProducts = collection.products.sort((productA, productB) => Date.parse(productA.createdAt) - Date.parse(productB.createdAt))

    $("p.collection_price_start").text(`Price starts at ${formatPrice(cheapestProduct.variants[0].priceV2)} ·`)

    collectionProducts.forEach(collectionProduct => {
      const titleArray = collectionProduct.title.split(" · ");
      const variantID = decode(collectionProduct.variants[0].id).split("/").slice(-1);
      const collectionSection = $(`
        <div class="collection_section">
          <div class="slider_outer_container">
            <div class="slider image_container" data-slick='{"slidesToShow": 1, "slidesToScroll": 1, "dots": true,}'>
              ${collectionProduct.images.map(image => {
                return (`
                  <div class="image_container">
                    <img src="${image.src}" class="zoomable" alt="Product" width="800" height="600"/>
                  </div>
                `)
              }).toString()}
            </div>
            <div class="image_border"></div>
          </div>
          <div class="text_container">
            <div class="text_inner_container">
              <h2>${titleArray[0]}</h2>
              <p><b>Item no.:</b> ${titleArray[2]}</p>
              <p><b>Material:</b> ${titleArray[1]}</p>
              <p><b>Price:</b> ${parseInt(collectionProduct.variants[0].compareAtPrice) > parseInt(collectionProduct.variants[0].price) ? "<span class='strikethrough'>" + formatPrice(collectionProduct.variants[0].compareAtPriceV2) + "</span>" : "" } ${formatPrice(collectionProduct.variants[0].priceV2)}</p>
              ${!!collectionProduct.description ? "<p><b>Notes:</b> " + collectionProduct.description + "</p>" : ""}
              ${!!collectionProduct.availableForSale ? `<a href="https://holgersindbaek.myshopify.com/cart/${variantID}:1?channel=buy_button&locale=${language}&currency=${cheapestProduct.variants[0].priceV2.currencyCode}" target="_blank" type="button" class="button buy">Buy item ${titleArray[2]}</a>` : `<button type="button" class="button sold">Sold</button>`}
            </div>
          </div>
        </div>
      `)
      $('#collection').append(collectionSection);
    });

    mediumZoom(document.querySelectorAll('.zoomable'), {
      margin: 20,
      background: "rgba(255, 249, 242, 0.95)"
    })

    $(".slider").slick({
      dots: true,
      infinite: true
    });
  });
}

function formatPrice(price) {
  return new Intl.NumberFormat(language, { style: 'currency', currency: price.currencyCode, currencyDisplay: "symbol", maximumSignificantDigits: 21 }).format(price.amount)
}
